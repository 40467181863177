.upload-input {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: stretch;
}

.upload-input input {
  margin-left: 5px;
}

.upload-input span {
  min-width: 85px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: -15px;
}

.answer-image-area {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: stretch;
  margin-left: 10px;
}

.answer-line {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  background-color: #f0eeee;
  margin: 10px 25px 10px 25px;
  border-radius: 10px;
  padding: 10px 0px 10px 0px;
}

.answer-line-image-upload {
  width: 545px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  background-color: #f0eeee;
  margin: 10px 25px 10px 25px;
  border-radius: 10px;
  padding: 10px 0px 10px 0px;
}

.answer-image-upload {
  width: 200px;
  height: 130px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  margin-left: 20px;
}

.answer-image-upload img {
  max-width: 100%;
  max-height: 100%;
}

.right-answer-image-upload {
  width: 200px;
  margin: 0px 10px 0px 20px;
}

.order-image-upload {
  width: 100px;
  margin: 0px 10px 0px 20px;
}

.render-logo {
  position: relative;
}
.validation-msg-image-upload {
  position: absolute;
  color: red;
  top: 90px;
  left: 5px;
}

@media screen and (max-width: 450px) {
  .answer-line-image-upload {
    width: 500px;
    height: 240px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    background-color: #f0eeee;
    margin: 10px 25px 10px 25px;
    border-radius: 10px;
    padding: 10px 0px 10px 0px;
  }

  .answer-image-upload {
    width: 270px;
    height: 75px;
    margin-left: 0px;
  }

  .answer-image-upload img {
    max-width: 100%;
    max-height: 100%;
  }
}
