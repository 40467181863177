.filters-area {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
}

.filters-area > * {
  margin-left: 10px;
}

.filter-search {
  cursor: pointer;
}

.select-filter {
  width: 45%;
}

.clear-filters {
  white-space: nowrap;
  cursor: pointer;
}
