.new-group-action-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: stretch;
}

.new-group-action-buttons > a {
  margin-right: 10px;
}

.new-group-action-buttons > a:hover {
  color: #51be78 !important;
}
