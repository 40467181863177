.active {
  background-color: #424242 !important;
  color: #ffffff !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.btn-actions-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  align-content: stretch;
}

.btn-action {
  margin-right: 5px;
}

.btn-edit {
  font-size: 15px;
  text-transform: uppercase;
  background-color: #424242;
  color: #fff;
  padding: 0 30px 0 30px;
  line-height: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  z-index: 1;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #424242 !important;
  font-weight: 500;
}

.btn-edit:hover {
  background-color: #fff !important;
  color: #424242 !important;
}

.btn-remove {
  font-size: 15px;
  text-transform: uppercase;
  background-color: #c82333;
  color: #fff;
  padding: 0 30px 0 30px;
  line-height: 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  z-index: 1;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #bd2130;
  font-weight: 500;
}

.btn-remove:hover {
  background-color: #fff;
  color: #c82333;
}

.client-column {
  width: 80%;
}
