.icon-color {
  color: #233d63 !important;
}

.custom-logo {
  vertical-align: middle;
  border-style: none;
  max-height: 40px;
  max-width: 265px;
}

.notification-btn[aria-expanded='true'] {
  background-color: #777777 !important;
  color: #fff;
}

.notification-btn {
  background-color: #fff !important;
  color: #424242 !important;
}

.notification-btn:hover {
  background-color: #fff !important;
}

.help-link {
  bottom: 2px;
}
