.custom-error-msg {
  color: red;
}

.input-dates {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: stretch;
}

.input-dates > * {
  margin-left: 10px;
}

.popover-help > * {
  margin-left: 10px;
}
