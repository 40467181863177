.status-message {
  padding-top: 10px;
}

.answer-letter {
  color: #233d63;
  padding-left: 16px;
  padding-top: 9px;
  font-size: 21px;
}
