.actions-buttons-area {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: stretch;
}

.action-button {
  margin-right: 5px;
}

.page-navigation-nav {
  color: #424242;
}

.icon-table {
  display: table !important;
}

.page-size {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  padding-top: 9px;
}

.page-size > label {
  padding-left: 10px;
  padding-right: 10px;
  color: #424242;
}

.select-input {
  padding-bottom: 5px;
}

.btn-add-client {
  margin-top: 20px;
}

.logo-thumbnail {
  height: 60px;
}

.no-description {
  margin-right: 0px !important;
}

.react-icons {
  margin-bottom: 2px;
}

.action-btn .questions-btn {
  background-color: rgba(127, 136, 151, 0.1);
  color: #7f8897;
  border-color: rgba(127, 136, 151, 0.3);
}

.input-dates {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: stretch;
}

.input-dates > * {
  margin-right: 20px;
}

.validation-msg-dates > span {
  color: red;
}

.search-table-area-with-add-button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: 20px;
}

.search-table-input {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}

.search-table-input > span {
  margin: 10px 0px 0px 5px;
  cursor: pointer;
}

#search-status {
  color: #495057;
  font-size: 1rem;
}

#form-search-status {
  width: 250px;
  height: 5%;
  color: #495057;
  font-size: 1rem;
}

.options-groups {
  font-size: 16px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.options-groups {
  padding-top: 5px;
  padding-bottom: 5px;
}

.options-groups:hover {
  background-color: #51be781a;
  color: #51be78;
}

.options-groups:checked {
  background-color: #424242;
  color: #ffffff;
}

.options-groups:active {
  background-color: #424242;
  color: #ffffff;
}

.input-filters-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  margin-top: 0px;
  padding: 0px 10px;
  width: 900px;
}

#search {
  margin-top: 17px;
}
