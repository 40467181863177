.actions-buttons-area {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: stretch;
}

.action-button {
  margin-right: 5px;
}

.page-navigation-nav {
  color: #424242;
}

.icon-table {
  display: table !important;
}

.page-size {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  padding-top: 9px;
}

.page-size > label {
  padding-left: 10px;
  padding-right: 10px;
  color: #424242;
}

.select-input {
  padding-bottom: 5px;
}

.btn-add-client {
  margin-top: 20px;
}

.logo-thumbnail {
  height: 60px;
}

.no-description {
  margin-right: 0px !important;
}

.react-icons {
  margin-bottom: 3px;
  font-size: 18px;
}

.action-btn .questions-btn {
  background-color: rgba(127, 136, 151, 0.1);
  color: #7f8897;
  border-color: rgba(127, 136, 151, 0.3);
}

.popover-help {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: stretch;
}

.popover-help > * {
  margin-left: 10px;
}

.search-table-area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: flex-start;
}

.search-table-input {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.search-table-input > span {
  margin: 10px 0px 0px 5px;
  cursor: pointer;
}

.search-table-input > input {
  margin-right: 10px;
}

.export-candidate-applications {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
