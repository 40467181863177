.questions-bottom-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
}

.btn-delete-question {
  padding-top: 13px;
}

.btn-delete-answer {
  float: right;
}

.btn-delete-question {
  padding-top: 13px;
}

.btn-remove-question {
  padding-top: 15px;
}

.btn-save-question {
  padding-top: 29px;
  float: right;
}

.btn-upload {
  float: right;
  padding-top: 42px;
  margin-left: -13px;
}

.upload-input-background {
  width: 100%;
  height: 100%;
  background-color: #f0eeee;
  border-radius: 10px;
  padding: 15px;
}

.client-logo-area {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  background-color: #f0eeee;
  border-radius: 10px;
}

.client-logo-area img {
  max-width: 80%;
  max-height: 80%;
}

.info-message {
  padding-top: 10px;
}

.btn-inline {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: stretch;
  left: 140px;
  bottom: 75px;
}

.btn-inline > * {
  margin-left: 10px;
}

.select-question-type {
  width: 146px;
}

.evaluation-answer-label {
  margin-top: 10px;
  color: #233d63;
}

.evaluation-answer-description {
  padding: 25px 15px 0px 15px;
}

.answer-text {
  font-style: italic;
}

.no-question-message-area {
  height: 100px;
  background-color: #f0eeee;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  margin-top: 40px;
}

.no-question-message {
  text-align: center;
  color: #233d63;
  font-size: 30px;
}
