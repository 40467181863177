.btn-file-upload {
  font-size: 17px;
  text-transform: uppercase;
  background-color: #424242;
  color: #fff;
  padding: 0 30px 0 30px;
  line-height: 35px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  z-index: 1;
  display: inline-block;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #424242;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 13px;
}

.btn-file-upload:hover {
  background-color: #fff;
  color: #424242;
  border: 1px solid #424242;
}

.file-name {
  margin-left: 10px;
}
